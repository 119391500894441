//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

///Chantal Font Family
$font-chantal: "Chantal Medium";
$font-roboto: "Roboto", sans-serif;

/// Regular font family
$font-opensans: "Open Sans";

/// @type List
$text-font-stack: $font-opensans, "Helvetica Neue Light", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: #222 !default;

/// Main brand color
/// @type Color
$brand-color: #e50050 !default;

/// Light grey
/// @type Color
$light-grey: #ededed !default;

/// Medium grey
/// @type Color
$mid-grey: #999 !default;

/// Dark grey
/// @type Color
$dark-grey: #444 !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "../assets/" !default;

//Billsby Labs Color
$white: #ffffff;
$black-shade: #252422;

$orange: #fe7f2d;
$carnation-pink: #FFCCAB;
$red: #d22630;
$silver: #c5c5c5;
$grey: #00000029;
$dark-grey: #8b867c;
$gainsboro: #252422b3;
