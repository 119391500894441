//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
html, .App, body, #root {
  height: 100%;
  margin: 0;
}
// @media screen and (min-width: 768px) and (max-width: 1199px) {
// }

.App {
  position: relative;

  display: flex;
  flex-direction: column;
}

.app-wrapper {
  flex: 1 0 auto;
  // margin-bottom: 132px;
}

.page-footer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;

  flex-shrink: 0;
  padding-top: 48px;
  padding-bottom: 50px;
  width: 100%;

  font-size: 14px;
  color: $silver;

  background-color: $black-shade;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    color: $white;
    text-decoration: underline;
  }
}
