//main: ../main.scss

/**
 * Basic typography style for copy text
 */
body {
  color: $black-shade;
  font: 400 17px $font-roboto;
}

.t-chantal {
  margin-top: 0;
  margin-bottom: 0;

  font-family: $font-chantal;
  font-weight: 500;
}

.heading {
  @extend .t-chantal;

  font-size: 48px;
}

.subheading {
  @extend .t-chantal;

  font-size: 36px;
}

.t-regular {
  @extend .t-chantal;

  font-size: 24px;
}

p {
  margin-top: 20px;
}

@include respond-to(sm-down) {
  .heading {
    font-size: 34px;
  }
}