.labs-form {
  padding-top: 33px;
  padding-bottom: 50px;

  .labs-form-group {
    margin-bottom: 35px;
    width: 100%;

    &:nth-child(1) .labs-form-input,
    &:nth-child(3) .labs-form-input {
      flex-wrap: wrap;
    }
  }
}

.labs-form-label {
  width: 20%;

  font-weight: 600;
  font-size: 21px;
}

.labs-form-input {
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 80%;

  input {
    padding: 15px 12px;
    width: 100%;

    font-size: 21px;
    color: $black-shade;

    background-color: $white;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 0px 4px $grey;

    @include on-event {
      box-shadow: 0px 0px 4px $grey;
      outline: 0;
    }
  }

  .t-input,
  .t-note {
    font-size: 21px;
  }

  .t-input {
    margin-left: 13px;
  }

  .t-note {
    margin-bottom: 0;
    width: 100%;

    color: $dark-grey;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@keyframes progress-animation {
  0% {width: 0%;}
  20% {width: 10%;}
  40% {width: 30%;}
  50% {width: 60%;}
  100% {width: 85%;}
}

.loading-generate {
  position: relative;

  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  background-color: $carnation-pink;
  box-shadow: inset 0px 0px 0px 1px $carnation-pink;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    display: flex;
    width: 85%;
    height: 100%;

    background: $orange;

    animation: progress-animation 5s ease-in-out;
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:hover {
    background-color: $carnation-pink;
  }
}

@include respond-to(md-up) {
  .labs-form-btn-generate {
    margin-left: auto;
    width: 80%;
    svg {
      margin-right: 10px;
    }
  }
}

@include respond-to(sm-down) {
  .labs-form-label {
    margin-bottom: 15px;
    width: 100%;
  }

  .labs-form-input {
    .t-input,
    .t-note {
      font-size: 16px;
    }
  }
}

@include respond-to(xs) {
  .labs-form-label {
    font-size: 18px;
  }
}
