//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.page-header {
  padding: 23px 0;

  background-color: $orange;
}

.navbar-brand {
  display: flex;
  
  .navbar-logo {
    display: flex;
    align-items: center;
  }

  img {
    width: 89.57px;
    max-width: 100%;
    height: 28.14px;
  }

  span {
    padding-left: 6.43px;

    font-family: $font-chantal;
    color: $white;
  }

  a {
    @include on-event {
      text-decoration: none;
    }
  }
}

.index {
  .modal-action-holder {
    display: none;
  }
}