//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.main-home {
  padding-top: 94px;
  color: $white;

  background-color: $orange;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: $orange;
  }

  .heading,
  p {
    margin-right: auto;
    max-width: 100%;
  }

  .heading {
    line-height: 48px;
  }
}

@include respond-to(lg-up) {
  .main-home {
    height: calc(100vh - 301px);

    .heading {
      width: 724px;
    }
  
    p {
      width: 609px;
    }
  }
}

@include respond-to(md-down) {
  .main-home {
    padding-bottom: 152px;
  }
}