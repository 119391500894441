//main: ../main.scss

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 14.6px;
  // padding-bottom: 15.4px;
  width: 100%;
  max-width: 100%;
  height: 58px;

  font-weight: 500;
  font-size: 21px;
  color: $white;
  text-align: center;

  border: 1px solid transparent;
  border-radius: 4px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  @include on-event {
    color: $white;
    text-decoration: none;
  }
}

.btn-orange {
  @extend .btn;
  background-color: $orange;
}
.btn-red {
  @extend .btn;
  background-color: $red;
}

.btn-black {
  @extend .btn;

  width: 168px;
  height: 40px;

  font-size: 17px;

  background-color: $black-shade;
}

@include respond-to(md-up) {
  .btn-orange:hover {
    background-color: $black-shade;
  }

  .btn-red:hover{
    background-color: $black-shade;
  }

  .btn-black:hover {
    color: $black-shade;

    background-color: transparent;
    border-color: $black-shade;
  }
}

@include respond-to(xs) {
  .btn {
    font-size: 16px;
  }
}
