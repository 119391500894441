body.modal-open {
  overflow: hidden;
}

.modal-wrapper {
  position: absolute;
  top: 100px;
  left: 50%;
  z-index: 25;

  display: none;
  overflow-y: auto;
  height: 100%;
  
  transform: translateX(-50%);

  &.show {
    display: flex;
  }
}

.modal-dialog {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 443px;
}

.modal-content {
  padding: 40px;
  width: 600px;
  max-width: 100%;

  background-color: $white;
  border-radius: 4px;

  .btn-orange {
    width: 168px;
    height: 40px;

    font-size: 17px;
  }
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 26;
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  color: $white;

  background-color: $orange;

  cursor: pointer;
}

.t-modal-action {
  font-weight: 500;
  font-size: 17px;
  color: $white;

  background-color: transparent;
  border: 0;

  cursor: pointer;

  @include on-event {
    outline: 0;
  }
}

.overlay {
  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    background-color: $gainsboro;
  }
}

@include respond-to(md-down) {
  .modal-wrapper {
    top: 0;
    left: 0;

    padding-left: 15px;
    padding-right: 15px;

    transform: translateX(0);

    &.show {
      // height: 100%;
      overflow: auto;
    }
  }

  .modal-content {
    position: relative;
    top: 100px;

    padding: 15px;
    // padding-bottom: 89px;
    margin-bottom: 50px;
    width: 100%;
    // height: 100%;

    .subheading {
      font-size: 28px;
    }
  }
}

@include respond-to(xs) {
  .modal-content {
    padding-bottom: 58px;
    height: 100%;
  }
}

