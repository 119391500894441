.test-page {
  padding: 20px;
  .test-page-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 30px;
  }
}
.test-upload-group {
  margin-top: 20px;
  button {
    margin-right: 10px;
    padding: 10px;
  }
}
.test-upload-status {
  margin-top: 20px;
}
.test-uploaded-list {
  margin-top: 20px;
  font-size: 14px;
  ul {
    li {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
}

.test-error-list {
  margin-top: 20px;
  color: red;
  font-size: 14px;

  .test-error-title {
    font-size: 15px;
    font-weight: bold;
  }
}
