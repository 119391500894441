.hero {
  color: $white;

  background-color: $black-shade;

  .info-wrapper,
  .bg-wrapper {
    width: 50%;
  }

  .bg-wrapper {
    position: relative;
    
    height: 343px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .t-desc {
    margin-right: auto;
    width: 609px;
    max-width: 100%;
  }
}

@include respond-to(md-up) {
  .hero {
    .image-wrapper {
      display: none;
    }
  }
}

@include respond-to(md) {
  .hero {
    .bg-wrapper {
      height: 267px;
      background-size: contain;
    }
  }
}

@include respond-to(md-down) {
  .hero {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@include respond-to(sm-down) {
  .hero {
    .info-wrapper,
    .image-wrapper {
      width: 100%;

      text-align: center;
    }

    .bg-wrapper {
      display: none;
    }

    .flex-holder {
      flex-direction: column-reverse;
    }

    .subheading {
      margin-top: 20px;
    }

    img {
      width: 330px;
      max-width: 100%;
    }

    .t-desc {
      margin-left: auto;
    }
  }
}