@include respond-to(xl) {
  .main-subscription {
    .hero .bg-wrapper {
      background-size: 85%;
      background-position: 2px -16px;
    }
  }
}

@include respond-to(lg) {
  .main-subscription {
    .hero .bg-wrapper {
      background-size: cover;
      background-position: center center;
    }
  }
}

.error-message {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: red;
}
